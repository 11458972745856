import React, { useState } from "react"
import ReactPlayer from "react-player/vimeo"
import { motion, AnimatePresence } from "framer-motion"

function Backdrop({ children, onClick }) {
    return (
        <motion.div
            onClick={onClick}
            className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black/20"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {children}
        </motion.div>
    )
}

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
}

function Modal({ handleClose, text }) {
    return (
        <Backdrop onClick={handleClose}>
            <motion.div
                onClick={e => e.stopPropagation()}
                className="mx-auto"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <ReactPlayer
                    controls
                    width={1280}
                    height={720}
                    url="https://vimeo.com/180910803"
                />
            </motion.div>
        </Backdrop>
    )
}

export default function VideoHero() {
    const [modalOpen, setModalOpen] = useState(false)

    const close = () => setModalOpen(false)
    const open = () => setModalOpen(true)
    return (
        <>
            <section className="relative md:h-[958px]">
                <div className="container relative z-10 flex flex-col items-center justify-center w-full h-full space-y-5 md:items-start">
                    <h1 className="text-4xl text-center 2xl:text-5xl md:text-left">
                        Meet the New DIY Server
                        <br />
                        For Your Office or Home:
                    </h1>
                    <p className="text-3xl font-bold 2xl:text-5xl text-red">
                        antsle
                    </p>
                    <p className="text-center 2xl:text-2xl md:text-left md:text-lg">
                        Start launching VPSs in 10 seconds or less!
                    </p>
                    <button
                        onClick={() => (modalOpen ? close() : open())}
                        className="btn-red"
                    >
                        Watch The Video
                    </button>
                </div>
                <video
                    className="md:absolute top-0 left-0 w-screen md:h-[958px] object-cover z-0"
                    loop
                    controls={false}
                    autoPlay
                    muted
                    src="https://antsle.com/wp-content/uploads/2017/03/antsle_homepage_Video-Converted.mp4"
                ></video>
            </section>
            <AnimatePresence
                initial={false}
                exitBeforeEnter={true}
                onExitComplete={() => null}
            >
                {modalOpen && (
                    <Modal modalOpen={modalOpen} handleClose={close} />
                )}
            </AnimatePresence>
        </>
    )
}
