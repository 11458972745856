import React from "react"

const logos = [
    {
        link: "https://res.cloudinary.com/antsle/image/upload/v1632898831/client%20logos/dell_b_efozzh.svg",
        name: "Dell",
    },
    {
        link: "https://res.cloudinary.com/antsle/image/upload/v1632898961/client%20logos/nordstrom_b_og78lj.svg",
        name: "Nordstrom",
    },
    {
        link: "https://res.cloudinary.com/antsle/image/upload/v1632898903/client%20logos/ibm_b_tnkrk1.svg",
        name: "IBM",
    },
    {
        link: "https://res.cloudinary.com/antsle/image/upload/v1632898939/client%20logos/nokia_b_ffuavv.svg",
        name: "Nokia",
    },
    {
        link: "https://res.cloudinary.com/antsle/image/upload/v1632898812/client%20logos/cbs_b_qhw6zq.svg",
        name: "CBS",
    },
    {
        link: "https://res.cloudinary.com/antsle/image/upload/v1632898885/client%20logos/general_electric_b_nofrva.svg",
        name: "General Electric",
    },
    {
        link: "https://res.cloudinary.com/antsle/image/upload/v1632899000/client%20logos/PannState_b_ngvob1.svg",
        name: "PennState",
    },
]

export default function Partners() {
    return (
        <section className="container py-10 md:py-20">
            <p className="text-3xl font-bold text-center text-gray-800">
                Used by Game Changers at
            </p>
            <div className="grid justify-center grid-cols-2 gap-10 pt-10 md:flex place-items-center 2xl:space-x-20">
                {logos.map((logo, i) => (
                    <img
                        key={i}
                        className="h-32 transition-transform transform hover:scale-105"
                        src={logo.link}
                        alt={logo.name}
                    />
                ))}
            </div>
        </section>
    )
}
