import React from "react"

export default function CardWithIcon({ icon, title, description }) {
  return (
    <div className="relative flex flex-col items-center justify-center flex-1 px-6 pt-16 pb-8 mx-5 text-center transition-all rounded-lg shadow-lg hover:shadow-2xl">
      <div className="absolute flex items-center justify-center w-20 h-20 text-4xl text-white transform -translate-x-1/2 bg-gray-600 rounded-full shadow-md -top-10 left-1/2">
        {icon}
      </div>
      <p className="text-2xl font-semibold">{title}</p>
      <p>{description}</p>
    </div>
  )
}
