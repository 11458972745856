import React from "react"
import CardWithIcon from "../components/Blocks/CardWithIcon"
import ImageLeft from "../components/Blocks/ImageLeft"
import HowItWorks from "../components/HowItWorks"
import VideoHero from "../components/VideoHero"
import IconWithTitle from "../components/Blocks/IconWithTitle"
import Partners from "../components/Partners"
import meetantlseImg from "../images/meetantsle.png"
import deployImg from "../images/deploy.png"
import { ImTruck } from "react-icons/im"
import {
    ImAccessibility,
    ImUsers,
    ImLibrary,
    ImTarget,
    ImSearch,
    ImClipboard,
    ImMeter,
    ImFeed,
    ImDrive,
    ImNotification,
    ImStatsBars,
} from "react-icons/im"
import { RiMoneyDollarCircleLine, RiHtml5Fill } from "react-icons/ri"
import { MdCloudDone } from "react-icons/md"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/SEO"

const packages = [
    {
        name: "antsle one",
        specs: [
            "Intel CPU @ 2.40 GHz, 4 Cores",
            "8 GB / 16 GB ECC RAM",
            "up to 5 TB Internal Storage",
        ],
        price: "Starting at $759",
        best: false,
    },
    {
        name: "antsle one Pro",
        specs: [
            " Intel CPU @ 2.40 GHz, 8 Cores",
            "16 GB / 32 GB ECC RAM",
            "up to 8 TB Internal Storage",
        ],
        price: "Starting at $1,299",
        best: true,
    },
    {
        name: "antsle one Ultra",
        specs: [
            " Intel CPU @ 2.40 GHz, 8 Cores",
            "32 GB / 64 GB ECC RAM",
            "up to 16 TB Internal ﻿Storage",
        ],
        price: "Starting at $2,149",
        best: false,
    },
]

export default function HomelabPage() {
    return (
        <>
            <Seo title="Homelab" />
            <VideoHero />
            <Partners />
            <section className="container py-10 md:py-20">
                <div className="text-center">
                    <h1 className="text-5xl font-bold text-red">
                        Meet antsle.
                    </h1>
                    <p className="text-xl">
                        The on-premise solution for your VPS needs.
                    </p>
                </div>
                <ImageLeft image={meetantlseImg}>
                    <ul className="space-y-3 text-xl cardList">
                        <li>
                            <b>Easily host from your home/office:</b> The
                            perfect interplay of hardware and software
                        </li>
                        <li>
                            <b>Ready out of the box:</b> Own it. Set it. Deploy
                            it.
                        </li>
                        <li>
                            <b>Take back control:</b> No more hosting fees, keep
                            your data local.
                        </li>
                    </ul>
                </ImageLeft>
                <div className="flex flex-col mt-20 space-y-20 md:space-y-0 md:mt-10 md:flex-row">
                    <CardWithIcon
                        icon={<ImAccessibility />}
                        title="FREEDOM"
                        description="With antsle, you physically own your data and keep it local. Outsmart the giants! Enjoy unlimited room to experiment."
                    />
                    <CardWithIcon
                        icon={<RiMoneyDollarCircleLine />}
                        title="SAVE MONEY"
                        description="It's more than just saving on monthly hosting fees: It's the peace of mind to create Virtual Private Servers at will, anytime you want!"
                    />
                    <CardWithIcon
                        icon={<MdCloudDone />}
                        title="FULL CONTROL"
                        description="We provide the tools, you decide what to do with it. Configure antsle any way you want. YOU are in control, not Google or Amazon."
                    />
                </div>
            </section>
            <HowItWorks />
            <section className="py-10 bg-gray-50 md:py-20">
                <div className="container">
                    <div className="text-center">
                        <h1 className="text-5xl font-bold text-red">
                            The Tech.
                        </h1>
                        <p className="text-xl">
                            Designed, assembled & developed in California.
                        </p>
                    </div>
                    <div className="flex flex-col items-center mt-10 space-y-10 md:mt-20 md:space-y-0 md:space-x-20 md:flex-row">
                        <div className="md:w-1/2">
                            <StaticImage src="../images/thetech1.png" />
                        </div>
                        <div className="flex flex-col space-y-5 text-xl md:w-1/2">
                            <h3 className="text-3xl text-red">
                                Get Your Own Server Flat Rate
                            </h3>
                            <p>
                                No more worrying about extra cost when you
                                launch a new Virtual Server! The antsle Private
                                Cloud Server lets you run a mix of 100+ antlets
                                at the same time.
                            </p>
                            <p>
                                With up to 64 GB RAM and 16 TB disk space,
                                antsle packs a punch! And we designed it to be
                                100% silent. No fans, no vent holes. All parts
                                were hand-selected for performance, and
                                developed for ease of use.
                            </p>
                            <StaticImage
                                objectFit="contain"
                                src="../images/thetech2.png"
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 pb-10 mt-20 border-b opacity-50 gap-y-5 md:gap-y-0 md:flex">
                        <IconWithTitle icon={<ImFeed />} title="Fanless" />
                        <IconWithTitle icon={<ImDrive />} title="SSD-based" />
                        <IconWithTitle
                            icon={<ImNotification />}
                            title="Fault tolerant"
                        />
                        <IconWithTitle
                            icon={<ImStatsBars />}
                            title="Easy VPS Mgmt"
                        />
                    </div>
                    <ImageLeft image={deployImg}>
                        <h3 className="text-4xl text-red">
                            Deploy in 10 Seconds
                        </h3>
                        <p className="text-xl">
                            We're big fans of SIMPLE! No bloated dashboards or
                            complicated setup processes here: Get set up in less
                            than 5 minutes & Deploy any antlet in less than 10
                            seconds.
                        </p>
                        <p className="text-xl">
                            The antman™ GUI lets you easily create/manage/deploy
                            your Virtual Servers, while our Linux-based
                            antsleOS™ really turns your antsle into a Private
                            Cloud Server.
                        </p>
                        <a href="#" className="btn-red">
                            Watch antman in Action
                        </a>
                    </ImageLeft>
                    <p className="w-2/3 mx-auto mt-20 text-xl text-center text-blue-400">
                        <p className="italic">
                            "People who are really serious about software,
                            should make their own hardware!" -{" "}
                            <span className="not-italic">Alan Kay</span>
                        </p>
                    </p>
                </div>
            </section>
            <section className="container py-10 md:py-20">
                <div className="text-center">
                    <h1 className="text-5xl font-bold">Who is it for?</h1>
                    <p className="text-xl">
                        Designed for Developers. Used by Many!
                    </p>
                </div>
                <div className="grid grid-cols-2 mt-20 gap-y-5 md:gap-y-0 md:flex">
                    <IconWithTitle icon={<ImUsers />} title="Businesses" />
                    <IconWithTitle icon={<RiHtml5Fill />} title="Developers" />
                    <IconWithTitle
                        icon={<ImLibrary />}
                        title="Schools & Universities"
                    />
                    <IconWithTitle icon={<ImTarget />} title="Gamers" />
                </div>
                <hr className="my-10" />
                <div>
                    <p className="text-lg text-center">
                        No matter the application: antsle has you covered.
                    </p>
                    <div className="flex flex-col mt-20 space-y-16 md:space-y-0 md:flex-row">
                        <CardWithIcon icon={<ImSearch />} title="Research" />
                        <CardWithIcon icon={<ImClipboard />} title="dev/test" />
                        <CardWithIcon icon={<ImMeter />} title="Production" />
                    </div>
                </div>
            </section>
            <section className="container py-10 md:py-20">
                <div className="text-center">
                    <h1 className="text-3xl font-bold md:text-5xl text-red">
                        Own It, Stop Renting!
                    </h1>
                    <p className="text-xl">
                        No hidden fees. Payment Plans﻿ ﻿available{" "}
                        <a href="#">(More Info)</a>.
                    </p>
                    <StaticImage
                        className="w-20 h-auto md:w-28"
                        src="../images/fees.png"
                        alt=""
                    />
                </div>
                <div className="grid grid-cols-1 py-10 gap-y-10 md:gap-y-0 md:grid-cols-3 gap-x-10 md:py-20">
                    {packages.map((item, i) => (
                        <div
                            key={i}
                            className={` bg-gray-100 shadow-xl hover:shadow-2xl transition-all rounded-xl ${
                                item.best ? "border border-red" : ""
                            }`}
                        >
                            <div
                                className={`py-5 my-10 text-white scale-x-105 ${
                                    item.best ? "bg-red-600" : "bg-black"
                                } shadow rounded-xl`}
                            >
                                <p className="text-4xl text-center">
                                    {item.name}
                                </p>
                            </div>
                            <div className="mx-auto text-xl text-center">
                                ⭐️⭐️⭐️⭐️⭐️
                            </div>
                            <div className="flex flex-col items-center p-5 space-y-5 text-xl">
                                <a
                                    href="#"
                                    className="font-semibold underline hover:no-underline"
                                >
                                    antsleOS™ + antman™
                                </a>
                                <ul className="space-y-3 text-center">
                                    {item.specs.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                                <a
                                    href="#"
                                    className="underline hover:no-underline text-red"
                                >
                                    See Full Specs
                                </a>
                                <p className="text-3xl font-semibold">
                                    {item.price}
                                </p>
                                <button className="w-full btn">
                                    CONFIGURE
                                </button>

                                <ul className="pt-8 space-y-3 leading-relaxed border-t border-gray-300 cardList">
                                    <li>
                                        <b>100% Risk-Free:</b> Full 30-day money
                                        back guarantee. No questions asked!
                                    </li>
                                    <li>
                                        <b>FREE</b> Domestic Return Shipping
                                    </li>
                                </ul>
                                <div className="flex space-x-5">
                                    <StaticImage
                                        objectFit="contain"
                                        className="w-1/2"
                                        src="../images/amazon.png"
                                        alt="Avaliable on Amazon"
                                    />
                                    <StaticImage
                                        objectFit="contain"
                                        className="w-1/2"
                                        src="../images/newegg.png"
                                        alt="Avaliable on NewEgg"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section className="py-10 bg-gray-100 md:py-20">
                <div className="container flex flex-col items-center justify-center space-y-3">
                    <ImTruck className="text-6xl text-red" />
                    <h1 className="text-4xl font-bold text-center">
                        Love It - or your money back!
                    </h1>
                    <p className="mx-auto text-xl text-center md:w-1/2">
                        Experience an antsle Private Cloud Server in your own
                        home or office. We'll take any antsle back within 30
                        days, no questions asked.
                    </p>
                </div>
            </section>
            <section className="container flex flex-col py-10 md:flex-row md:py-20">
                <div className="md:w-1/2">
                    <StaticImage
                        objectFit="contain"
                        src="../images/review3.jpg"
                        alt=""
                    />
                    <StaticImage
                        objectFit="contain"
                        src="../images/review1.png"
                        alt=""
                    />
                </div>
                <div className="md:w-1/2">
                    <StaticImage
                        objectFit="contain"
                        src="../images/review2.png"
                        alt=""
                    />
                </div>
            </section>
        </>
    )
}
