import React from "react"

export default function IconWithTitle({ icon, title, className }) {
    return (
        <div
            className={`flex flex-1 items-center justify-center space-y-5 flex-col ${className}`}
        >
            <div className="text-6xl text-gray-800 transition-colors hover:text-red">
                {icon}
            </div>
            <p className="w-2/3 mx-auto text-xl text-center md:text-2xl">
                {title}
            </p>
        </div>
    )
}
